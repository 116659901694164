import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Row, Col, Typography } from "antd"

import Layout from "../../components/layout"
import Head from "../../components/head"
import BetaTestersLogos from "../../components/beta-testers-logos"
import IntegrationsLogos from "../../components/integrations-logos"
import ComingTag from "../../components/coming-tag"
import FreeTrialCta from "../../components/free-trial-cta"
import CtaRow from "../../components/cta-row"

const { Title, Paragraph, Text } = Typography

const Tpe = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      oneImage: file(relativePath: { eq: "suivi-tresorerie-image-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      twoImage: file(relativePath: { eq: "anticiper-tresorerie-image-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      threeImage: file(relativePath: { eq: "multi-scenarios-image-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      fourImage: file(relativePath: { eq: "suivi-tresorerie-image-3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      michaelMichettiImage: file(relativePath: { eq: "michael-michetti.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      emSolarImage: file(relativePath: { eq: "em-solar-log.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      arrowOneImage: file(relativePath: { eq: "arrow-down-1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowTwoImage: file(relativePath: { eq: "arrow-down-2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90)
        }
      }
    }
  `)

  const description = "Logiciel de gestion de trésorerie pour TPE. Gérez et anticipez facilement votre trésorerie avec un outil simple, visuel et automatisé. Gagnez du temps et de la visibilité."

  return (
    <Layout>
      <Head
        title="Outil de gestion de trésorerie pour TPE"
        description={description}
        canonical="https://rocketchart.co/solutions/tpe/"
      >
      </Head>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 13, offset: 0, order: 0 }}>
          <Title>Comment des TPE comme EM-Solar gèrent leur trésorerie simplement </Title>
          <Paragraph style={{ color: "black" }}>Gérez et anticipez facilement votre trésorerie pour piloter votre entreprise en toute sérénité. Gagnez du temps et de la visibilité avec un suivi automatisé et fiable.</Paragraph>
          <CtaRow />
        </Col>
        <Col xs={{ span: 16, offset: 4, order: 0 }} lg={{ span: 11, offset: 0, order: 1 }} justify="center">
          <div style={{ marginBottom: "10px", marginRight: "10px" }}>
            <GatsbyImage image={data.emSolarImage.childImageSharp.gatsbyImageData} alt="logo EM Solar" />
          </div>
          <Title level={4}>« Ne cherchez plus, ils l'ont fait - simplement BRAVO !! Fini Excel et fini le temps infini à passer pour gérer sa trésorerie et ses prévisions. RocketChart est très agréable, facile d'utilisation et comporte toutes les fonctionnalités attendues pour une bonne gestion de trésorerie. »</Title>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <GatsbyImage image={data.michaelMichettiImage.childImageSharp.gatsbyImageData} alt="Michael Michetti - président de EM Solar" />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px" }}>
              <Text strong>Michael Michetti</Text>
              <Text>Président - EM Solar</Text>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="content-container" gutter={40} type="flex" justify="center" style={{ marginTop: "120px" }}>
        <Col span={24} align='center'>
          <Text strong style={{ color: '#6a737d' }}>Ils gèrent leur trésorerie simplement avec RocketChart</Text>
        </Col>
        <BetaTestersLogos />
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "80px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowTwoImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 2" />
          </div>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.oneImage.childImageSharp.gatsbyImageData} alt="Synchronisation bancaire en temps réel" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Gagnez du temps avec la synchronisation bancaire</Title>
          <Paragraph style={{ color: "black" }}>Oubliez les imports manuels de vos relevés bancaires dans un tableur Excel. Connectez vos banques et obtenez une visibilité instantanée sur votre position de trésorerie en temps réel. Visualisez votre trésorerie sur une interface claire et personnalisable et suivez vos indicateurs de gestion.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={2}>Gagnez en visibilité avec des prévisions de trésorerie fiables</Title>
          <Paragraph style={{ color: "black" }}>Projetez en toute simplicité votre trésorerie prévisionnelle à 1, 3, 6 ou 12 mois pour anticiper d’éventuelles difficultés financières et mettre en place les actions correctives (décalage paiement fournisseur, ligne de crédit, prêt bancaire, etc.)</Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.twoImage.childImageSharp.gatsbyImageData} alt="Prévisions de trésorerie fiables" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.threeImage.childImageSharp.gatsbyImageData} alt="Pilotage multi-scénarios" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Prenez les bonnes décisions avec les scénarios prévisionnels</Title>
          <Paragraph style={{ color: "black" }}>Simulez vos hypothèses business (recrutements, retards de paiement, baisse de CA, chômage partiel, etc.) et mesurez l’impact sur votre trésorerie. Appuyez vos décisions par des analyses précises de vos données financières et optez pour la meilleure stratégie.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <ComingTag />
          <Title level={2}>Automatisez le rapprochement banque / facturation</Title>
          <Paragraph style={{ color: "black" }}>Suivez les paiements clients, identifiez les retards de paiement et rapprochez vos factures à vos transactions. Vos justificatifs sont associés à chaque opérations bancaires pour faciliter la transmission des informations à votre Experts-Comptables.</Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.fourImage.childImageSharp.gatsbyImageData} alt="Rapprochement bancaire semi-automatisé" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" type="flex" justify="center" style={{ marginTop: "80px", marginBottom: "80px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowOneImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down" />
          </div>
        </Col>
        <Col span={12} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Intégrez vos outils de facturation, ERP et logiciels de comptabilité</Title>
          <Paragraph style={{ color: "black" }}>Synchronisez en temps réel vos factures dans RocketChart pour générer automatiquement votre prévisionnel de trésorerie et automatiser le rapprochement banque / facture.</Paragraph>
        </Col>
        <Col span={24} align='center' style={{ marginTop: '20px' }}>
          <IntegrationsLogos />
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "0px", marginBottom: "60px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col xs={24} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Démarrez maintenant votre essai gratuit</Title>
          <FreeTrialCta />
        </Col>
      </Row>

    </Layout>
  )
}

export default Tpe